export const navTopUserLinks = (loggedInUser) => {
  return [
    {
      to: '/',
      text: 'Home'
    },
    {
      text: 'Profile & Account',
      to: `/users/details/${loggedInUser?.uuid}`
    }
  ]
}
