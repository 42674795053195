// React
import { useContext, useEffect } from 'react'
// Routing
import { useHistory, useParams } from 'react-router'
// MainStem - UI
import { Alert, EventBus, Loader } from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Global - Context
import { AppContext } from 'context'

const PagePunchOutOrderGenerating = () => {
  // Routing
  const history = useHistory()
  const { buyersCookie } = useParams<any>()
  // Global - Context
  const { loggedInUser } = useContext(AppContext)

  const generateOrder = () => {
    mainStemApi.api.punchoutsOrderComplete({ buyersCookie }).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        EventBus.dispatch(window.parent.document, 'punchout-redirect-to-order', {
          orderUUID: apiResponse.data.orderUUID
        })
        // Redirect user to the new requisition
        history.push(`/requisitions/details/${apiResponse.data.orderUUID}`)
      } else {
        EventBus.dispatch(window.parent.document, 'punchout-generate-failed', null)
        // Redirect user to the purchase page with an error message
        history.push(`/punch-outs/purchase?orderGenerationFailed=true`)
        // Reload the page to reset the state
        window.location.reload()
      }
    })
  }

  useEffect(() => {
    if (loggedInUser) {
      generateOrder()
    }
  }, [loggedInUser])

  return (
    <>
      <Alert
        color='success'
        style={{
          margin: '25px auto',
          maxWidth: 'calc(100vw - 20px)',
          width: 800
        }}
        title='PunchOut Order Informaton Captured!'
      >
        <p>A new requisition is being generated for you now. You will be redirected to it shortly.</p>
      </Alert>
      <Loader />
    </>
  )
}

export default PagePunchOutOrderGenerating
