import styled from 'styled-components'

export const SCHeroWrapper = styled.div`
  background-image: url('https://images.unsplash.com/photo-1617791160505-6f00504e3519?q=80&w=2128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
  padding-bottom: 20px;
  padding-top: 30px;
  position: relative;
  width: 100%;
`

export const SCHeroTitle = styled.div`
  color: #fafafa;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 600;
  position: relative;
  text-align: center;
  text-shadow: rgb(0, 0, 0) 0px 1px;
`

export const SCHeroContent = styled.div`
  color: #fafafa;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.25;
  margin-top: 30px;
  padding: 0 40px;
  position: relative;
  text-align: center;
  text-shadow: rgb(0, 0, 0) 0px 1px;
`
