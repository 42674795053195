// MainStem - API
import { Api } from 'api-new'

const mainStemAPI = new Api()

export const loadDepartmentsByLocationID = (locationId: number): Promise<any> => {
  const apiRequest = {
    locationId: locationId
  }
  return mainStemAPI.api
    .departmentsList(apiRequest)
    .then((apiResponse) => {
      if (apiResponse.data.departments) {
        const formattedSelectOptions = apiResponse.data.departments.map((item) => {
          return {
            ...item, // It self + value and label
            value: item.id,
            label: item.name
          }
        })
        apiResponse.data.departments = formattedSelectOptions
      }
      return apiResponse.data ?? apiResponse
    })
    .catch((error) => {
      return error
    })
}
