import { LeafLogixTypes } from "types"

export const LeafLogixTypeAsEnum = (type: string) => {
  switch (type) {
    case 'Products':
      return LeafLogixTypes.Products
    case 'Rooms':
      return LeafLogixTypes.Rooms
    case 'Vendors':
      return LeafLogixTypes.Vendors
    default:
      return LeafLogixTypes.Products
  }
}

export const LeafLogixTypeAsString = (type: LeafLogixTypes) => {
  switch (type) {
    case LeafLogixTypes.Products:
      return 'Products'
    case LeafLogixTypes.Rooms:
      return 'Rooms'
    case LeafLogixTypes.Vendors:
      return 'Vendors'
    default:
      return 'Products'
  }
}
