import styled from 'styled-components'

export const SCCardSupplierCondensed = styled.div`
  background-color: #fafafa;
  border-color: #ccc;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
`
