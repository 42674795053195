// React
import { useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'

const NavTopSearch = () => {
  const history = useHistory()
  const { query } = useParams<any>()

  const [newQuery, setNewQuery] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (newQuery === '') {
      return
    }
    history.push(`/search/${newQuery}`)
    setNewQuery('')
  }

  const checkSubmit = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  return (
    <>
      <input
        autoComplete='off'
        defaultValue={query}
        id='ms-nav-top-container-right-search-input'
        onChange={({ target }) => setNewQuery(target.value)}
        onKeyDown={checkSubmit}
        placeholder='Search catalogs, locations, suppliers, users, etc...'
        style={{}}
        type='text'
      />
    </>
  )
}

export default NavTopSearch
