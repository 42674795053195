// React
import { Alert, Badge, FormattedDateTime } from '@mainstem/react-mainstem'
import { BadgeColors } from '@mainstem/react-mainstem/dist/components/Badge/types'
// 3rd Party - React BootStrap Table2 - Filter
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter'

import { TableModalJsonColumn } from '../../'

export const columnsIntegrationLogs = [
  {
    dataField: 'timestamp',
    headerClasses: 'border-0',
    text: 'Date',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter: (field: Date) => {
      return <FormattedDateTime datetime={field} />
    },
    filter: textFilter()
  },
  {
    dataField: 'message',
    headerClasses: 'border-0',
    text: 'Reason',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'note',
    headerClasses: 'border-0',
    text: 'Note',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    filter: textFilter(),
    formatter: (field: string, data: any) => {
      return (
        <>
          {field}
          <br />
          {data.error ? <Alert color='danger'>{data.error}</Alert> : ''}
        </>
      )
    }
  },
  {
    dataField: 'status',
    headerClasses: 'border-0',
    text: 'Status',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter: (field: string, data: any) => {
      let color: BadgeColors = 'success'
      switch (data.status) {
        case 'Success':
          color = 'success'
          break
        case 'Partial':
          color = 'warning'
          break
        case 'Fail':
        default:
          color = 'danger'
          break
      }
      return <Badge color={color}>{field}</Badge>
    },
    filter: selectFilter({
      options: {
        Success: 'Success',
        Partial: 'Partial',
        Failed: 'Fail'
      }
    })
  },
  {
    dataField: 'request',
    text: 'Request',
    sort: false,
    formatter: (field: any, data: any) => {
      return <TableModalJsonColumn json={data.request} title='Request' />
    }
  },
  {
    dataField: 'response',
    text: 'Response',
    sort: false,
    formatter: (field: any, data: any) => {
      return <TableModalJsonColumn json={data.response} title='Response' />
    }
  }
]

export const optionsIntegrationLogs = {
  sizePerPage: 10
}
