export type ILeafLogixSettings = {
  apiKey: string
  ReceiveInventoryQueue: IReceiveInventoryQueued[]
}

export type IReceiveInventoryQueued = {
  invoiceReceivedID: number
  queuedAt: Date
}

export enum LeafLogixTypes {
  Products,
  Rooms,
  Vendors
}
