// React
import { Fragment, useEffect } from 'react'
// Routing
import { useParams } from 'react-router-dom'
// MainStem - UI
import { Alert } from '@mainstem/react-mainstem'
// Global - Helpers
import { EventBus } from 'helpers'

const PagePunchOutOrderFailed = () => {
  const { buyerCookie } = useParams<{ buyerCookie: any }>()

  // Fires when page first loads
  useEffect(() => {
    // Not sure if we need this or not
  }, [buyerCookie])

  setTimeout(() => {
    EventBus.dispatch(window.parent.document, 'punchout-failed', {
      buyerCookie: buyerCookie
    })
  }, 1500)

  /* ======================
          return
  ====================== */
  // This works --
  // var someIframe = window.parent.document.getElementById('iframeid');
  // someIframe.parentNode.removeChild(window.parent.document.getElementById('iframeid'));

  return (
    <Fragment>
      <Alert
        color='danger'
        style={{
          margin: '25px auto',
          maxWidth: 'calc(100vw - 20px)',
          width: 800
        }}
        title='Order Captured Failed!'
      />
    </Fragment>
  )
}

export default PagePunchOutOrderFailed
