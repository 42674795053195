// React
import { useState } from 'react'
// MainStem - UI/UX
import { Button, Loader, Modal, ModalBody, SelectSingle, useOnFirstLoad } from '@mainstem/react-mainstem'
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - TypeScript Types
import { IModalOrderCreate } from './types'

const ModalOrderCreate: React.FC<IModalOrderCreate> = ({
  loading,
  onLocationSelected,
  onCloseModal
}: IModalOrderCreate) => {
  // Loading Indicators
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // View State
  const [locationOptions, setLocationOptions] = useState<ISelectOption[]>([])
  const [selectedLocation, setSelectedLocation] = useState<ISelectOption | undefined>()

  const loadLocations = () => {
    mainStemApi.api.locationsList().then((apiResponse) => {
      if (apiResponse.data.locations) {
        const locationOptions = apiResponse.data.locations
          .filter((location) => {
            return location.userRelated
          })
          .map((location) => {
            return {
              value: location.id,
              label: location.name
            } as ISelectOption
          })
        setLocationOptions(locationOptions)
      }
    })
  }

  const handleCloseModal = () => {
    if (typeof onCloseModal === 'function') {
      onCloseModal()
    }
  }

  const handleCreateNewQuote = () => {
    if (selectedLocation) {
      setIsLoading(true)
      // Prepare our api request to create the order
      const apiRequest = {
        locationId: selectedLocation.value
      }
      // Call our api to create the order
      mainStemApi.api
        .ordersCreate(apiRequest)
        .then((apiResponse) => {
          if (apiResponse.data.wasSuccessful && apiResponse.data.orderUUID && apiResponse.data.orderId) {
            onLocationSelected(apiResponse.data.orderUUID, apiResponse.data.orderId)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  // Fires when the component first loads
  useOnFirstLoad(() => {
    loadLocations()
  })

  return (
    <Modal onClose={handleCloseModal} scrollable={false}>
      <ModalBody>
        <h5
          style={{
            color: '#333',
            fontFamily: 'Poppins, sans-serif',
            fontSize: 16,
            fontWeight: 700,
            lineHeight: 1,
            marginBottom: 25,
            textAlign: 'center',
            textTransform: 'uppercase'
          }}
        >
          Create New Requisition
        </h5>
        <h5 className='fs--1'>Select A Location:</h5>
        {loading || !locationOptions ? (
          <Loader containerHeight={25} size='sm' />
        ) : (
          <>
            <SelectSingle
              onChange={(newValue) => setSelectedLocation(newValue)}
              options={locationOptions}
              placeholder='Select Location...'
              value={selectedLocation}
            />
          </>
        )}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
            justifyContent: 'center',
            marginTop: 25
          }}
        >
          <Button color='danger' onClick={handleCloseModal} style={{ minWidth: 150 }}>
            Cancel
          </Button>
          <Button
            color='primary'
            disabled={!selectedLocation}
            loading={loading || isLoading}
            onClick={handleCreateNewQuote}
            style={{ minWidth: 150 }}
          >
            Create New Requisition
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export { ModalOrderCreate }
