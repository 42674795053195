// React
import { createContext } from 'react'
// Global - API's
import { MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse } from 'api-new'

export const AppContext = createContext({} as AppContextDetails)

export interface AppContextDetails {
  loggedInUser: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse
  setLoggedInUser: React.Dispatch<
    React.SetStateAction<MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse | undefined>
  >
  userToken: string
  setUserToken: React.Dispatch<React.SetStateAction<string | undefined>>
  permissions: any
  setPermissions: React.Dispatch<React.SetStateAction<any | undefined>>
}
