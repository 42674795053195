import styled from 'styled-components'

export const SCProductButton = styled.div`
  align-items: center;
  background: linear-gradient(-135deg, rgba(255,255,255,1) 0%,rgba(229,229,229,.5) 100%);
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  color: #222;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding: 5px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: #440099;
    padding: 5px;
  }
`
