const permissionCheck = (user: Record<string, any>, group: string, shortCode: string, value: string) => {
  if (!user || !user.permissions) return false
  return (
    user.permissions.filter((permission: any) => {
      return permission.group === group && permission.shortCode === shortCode && permission.value === value
    }).length > 0
  )
}

export { permissionCheck }
