import { HTTP } from "config"

export async function apiAddressGetLatLon(apiRequest: any) {
  let response = {} as any

  await HTTP.post('address/getLatLong', apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        response = res.data
      }
    })
    .catch(() => {
      // means no response returned
    })

  return response
}
