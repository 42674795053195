import { theme } from '@mainstem/react-mainstem'
import styled from 'styled-components'

export const SCPackageCard = styled.div`
  background-color: #fff;
  border: 1px solid rgba(59, 18, 141, 0.3);
  border-radius: 6px;
`

export const SCPackageCardTitle = styled.div`
  background-color: rgba(68, 5, 153, 0.14);
  border-radius: 6px 6px 0 0;
  color: #440599;
  font-family: '${theme.fonts.primary}', sans-serif;
  font-weight: 600;
  line-height: 100%;
  padding: 8px 20px;
  text-transform: uppercase;
`

export const SCPackageCardContainer = styled.div``

export const SCPackageCardFeature = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
`
export const SCPackageCardFeatureIcon = styled.div`
  align-items: center;
  background-color: rgba(59, 18, 141, 0.1);
  border-radius: 100px;
  color: #3b128d;
  display: flex;
  font-size: 12px;
  height: 25px;
  justify-content: center;
  line-height: 100%;
  min-height: 25px;
  min-width: 25px;
  width: 25px;
`
export const SCPackageCardFeatureText = styled.div<{ bold: string }>`
  font-family: '${theme.fonts.primary}', sans-serif;
  margin-left: 20px;
  ${(props) =>
    props.bold === 'true'
      ? `
    font-size: 14px;
    font-weight: 600;`
      : `
    font-size: 12px;
    font-weight: 500;`}
  text-align: left;
`
