function ProductItem({ hit, components }) {
  return (
    <div className='aa-ItemWrapper'>
      <div className='aa-ItemContent'>
        <div className='aa-ItemIcon aa-ItemIcon--alignTop'>
          <img alt={hit.name} height='40' src={hit.image} width='40' />
        </div>
        <div className='aa-ItemContentBody'>
          <div className='aa-ItemContentTitle'>
            <components.Highlight attribute='name' hit={hit} />
          </div>
          {/* <div className='aa-ItemContentDescription'>
            <components.Snippet attribute='description' hit={hit} />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export { ProductItem }
