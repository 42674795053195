// React
import { useState } from 'react'
// MainStem - UI
import { Button, Modal, ModalBody } from '@mainstem/react-mainstem'
// Local - Styles
import { SCPre } from './styles'
// Local - Types
import { ITableModalJsonColumn } from './types'

const TableModalJsonColumn = ({ title, json }: ITableModalJsonColumn) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Button
        color='primary'
        onClick={() => {
          setShowModal(true)
        }}
      >
        <span style={{ whiteSpace: 'nowrap' }}>Show Full {title}</span>
      </Button>
      {showModal && (
        <Modal
          modalDialogStyle={{
            width: 'calc(100vw - 20px)',
            maxWidth: 1000
          }}
          onClose={() => {
            setShowModal(false)
          }}
        >
          <ModalBody>
            <h5
              style={{
                color: '#333',
                fontFamily: 'Poppins, sans-serif',
                fontSize: 16,
                fontWeight: 700,
                lineHeight: 1,
                marginBottom: 25,
                textAlign: 'center',
                textTransform: 'uppercase'
              }}
            >
              {title}
            </h5>

            <SCPre
              style={{
                backgroundColor: 'rgb(240, 240, 240)',
                border: '1px solid #ccc',
                borderRadius: 5,
                padding: 10
              }}
            >
              {JSON.stringify(json, null, 4)}
            </SCPre>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 10,
                justifyContent: 'center',
                marginTop: 25
              }}
            >
              <Button
                color='danger'
                onClick={() => {
                  setShowModal(false)
                }}
                style={{ minWidth: 150 }}
              >
                Close
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export { TableModalJsonColumn }
