// Routing
import { Link } from 'react-router-dom'
// Styled Components
import styled from 'styled-components'

export const SCNavTopAlertBar = styled(Link)`
  color: #fff;
  cursor: pointer;
  display: block;
  letter-spacing: 0.5px;
  margin-bottom: 3px;
  margin-top: 3px;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`
