// Local - Styled Components
import { SCNavTopAlertBar } from './styles'

const NavTopAlertBar = () => {
  return (
    <>
      <SCNavTopAlertBar to='/blog/order-management'>
        <strong>New Release!</strong>&nbsp;Improved Requisition &amp; Purchase Order Management
      </SCNavTopAlertBar>
    </>
  )
}

export default NavTopAlertBar
