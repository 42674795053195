// React
import { useEffect, useRef, useState } from 'react'
// Routing
import { Link, useLocation } from 'react-router-dom'
// React Error Boundary
import { ErrorBoundary } from 'react-error-boundary'
// Axios
import axios from 'axios'
// MainStem - UI
import {
  Button,
  FormattedCurrency,
  FormattedNumber,
  InputText,
  ListItemStat,
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  theme
} from '@mainstem/react-mainstem'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCloudDownloadAlt,
  faMessage,
  faMessageBot,
  faPaperclipVertical,
  faTimesCircle,
  faTrash
} from '@fortawesome/pro-light-svg-icons'
// Global - Pages
import PageErr from 'pages/err'
// Global - Routes
import { Routes } from 'routes'
// Local - Styled Components
import {
  SCPageWrapper,
  SCPageWrapperContent,
  SCPageWrapperSidebar,
  SCPageWrapperSidebarContent,
  SCPageWrapperSidebarMessage,
  SCPageWrapperSidebarMessages,
  SCPageWrapperSidebarTextArea,
  SCPageWrapperSidebarTitle
} from './styles'

const showSideKick = false

/***
 * This error wrapper is used to wrap the entire application in an Error Boundary.
 * The key on the ErrorBoundary allows the error to be reset anytime the user navigates to a new page/route.
 */
const ErrorWrapper: React.FC = () => {
  // Routing
  const location = useLocation()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingConversation, setLoadingConversation] = useState<boolean>(false)
  // Refs
  const messagesViewRef = useRef<HTMLDivElement>(null)
  // View State
  const [messageText, setMessageText] = useState<string>('')
  const [messages, setMessages] = useState<any[]>([])
  const [showSidebar, setShowSidebar] = useState<boolean>(false)
  const [showModalQuickOrder, setShowModalQuickOrder] = useState<boolean>(false)
  const [url, setURL] = useState<string>('')

  const scrollToBottom = () => {
    messagesViewRef.current?.scroll({ top: messagesViewRef.current.scrollHeight, behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const getProductData = () => {
    setLoading(true)
    setLoadingConversation(true)
    const newMessages = [...messages]
    axios
      .post(
        'https://api.zyte.com/v1/extract',
        {
          url: url,
          httpResponseBody: true,
          product: true,
          productOptions: { extractFrom: 'httpResponseBody' }
        },
        {
          auth: {
            username: '61a45fad0fa945df892ced5554548e16',
            password: ''
          }
        }
      )
      .then((response) => {
        // const httpResponseBody = Buffer.from(response.data.httpResponseBody, 'base64')
        const product = response.data.product

        if (product.name) {
          newMessages.push(
            {
              type: 'info',
              message: (
                <>
                  <div style={{ wordWrap: 'break-word' }}>
                    So I took at look at the URL&nbsp;
                    <a href='${url}' target='_blank'>
                      {url}
                    </a>
                    &nbsp;and found a product named <strong>{product.name}</strong>. Looks like it has a sku of{' '}
                    <strong>{product.sku}</strong> and its manufacturer part number is <strong>{product.mpn}</strong>{' '}
                    and costs{' '}
                    <strong>
                      <FormattedCurrency value={parseFloat(product.price)} />
                    </strong>
                    .
                  </div>
                </>
              )
            },
            {
              type: 'info',
              message: (
                <>
                  <Button
                    block={true}
                    color='fancy-gray'
                    disabled={false}
                    icon={faCloudDownloadAlt}
                    onClick={() => {
                      // TODO
                    }}
                  >
                    Import Product Into MainStem
                  </Button>
                </>
              )
            }
          )

          const apiRequest = {
            pageNumber: 1,
            pageSize: 25,
            query: product.name
          }
          axios
            .post('https://api.mainstem.io/api/global/shop/get-products', apiRequest)
            .then((apiResponse) => {
              if (apiResponse.data.products.length > 0) {
                newMessages.push({
                  type: 'info',
                  message: (
                    <>
                      I found some products in the MainStem database that match this product as well.
                      <br />
                      <br />
                      Here are a few:
                      <ul>
                        {apiResponse.data.products.slice(0, 5).map((product) => {
                          return (
                            <li key={product.id}>
                              <Link to={`/products/details/643928`}>
                                <strong>{product.name}</strong>
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    </>
                  )
                })
              }
            })
            .finally(() => {
              setShowModalQuickOrder(false)
            })
        }
      })
      .finally(() => {
        setLoading(false)
        setLoadingConversation(false)
        setMessages(newMessages)
      })
  }

  const getConversation = () => {
    const usersMessage = messageText
    setMessageText('')
    setLoadingConversation(true)
    if (usersMessage.indexOf('https://') > -1 || usersMessage.indexOf('http://') > -1) {
      setURL(usersMessage)
    }
    setMessages([...messages, { type: 'user', message: usersMessage }])

    setTimeout(() => {
      if (usersMessage.indexOf('https://') > -1 || usersMessage.indexOf('http://') > -1) {
        setMessages([...messages, { type: 'user', message: usersMessage }])
        getProductData()
        return
      }
      getLLM(usersMessage)
      setMessageText('')
      setLoadingConversation(false)
    }, 2500)
  }

  const getLLM = (query) => {
    const apiURL = 'https://copilot.mainstem.io/history/generate'

    const apiRequest = {
      messages: [
        {
          id: crypto.randomUUID(),
          role: 'user',
          content: query,
          date: new Date().toISOString()
        }
      ]
    }

    axios.post(apiURL, apiRequest).then((response) => {
      console.log('response', response)

      const responseData = response.data
      console.log('responseData', responseData)
      setMessages([
        ...messages,
        { type: 'user', message: query },
        {
          type: 'info',
          message: (
            <>
              ☹️&nbsp;I'm sorry, Garrett hasn't wired me up to the LLM large language model AI chat API yet from Azure.
              <br />
              <br />
              Maybe if you give him a cookie he will do it faster?
            </>
          )
        }
      ])
    })
  }

  useEffect(() => {
    if (
      location.pathname.indexOf('/requisitions/details/') > -1 ||
      location.pathname.indexOf('/requisitions/details/') > -1
    ) {
      const orderUUID = location.pathname.split('/details/').pop()

      setMessages([
        ...messages,
        {
          type: 'info',
          message: (
            <>
              Hey! I see you are looking at an order. I have some helpful information for you.
              <br />
              <br />
              <ListItemStat title='Possible Item Swaps'>
                <FormattedNumber value={2} />
              </ListItemStat>
              <Link to={`/requisition/recommendation/${orderUUID}`}>
                <Button block={true} color='fancy' disabled={false} icon={theme.icons.mainstem.supplyAudits}>
                  View The M.O.R.E. Report
                </Button>
              </Link>
            </>
          )
        }
      ])
    }

    if (location.pathname.indexOf('/products/details/') > -1) {
      setMessages([
        ...messages,
        {
          type: 'info',
          message: (
            <>
              Hey! I see you are looking at a product. I have some helpful information for you.
              <br />
              <br />
              This product is a great choice for your business. It has a high rating and is a best seller.
            </>
          )
        },
        {
          type: 'info',
          message: (
            <>
              <Button
                block={true}
                color='fancy-gray'
                disabled={false}
                icon={theme.icons.view}
                onClick={() => {
                  // TODO
                }}
              >
                View More Products Like This
              </Button>
            </>
          )
        }
      ])
    }
  }, [location.pathname])

  // useEffect(() => {
  //   if (loggedInUser && loggedInUser.username === 'notreal@mainstem.io') {
  //     setShowSidebar(true)
  //   }
  // }, [loggedInUser])

  return (
    <ErrorBoundary fallback={<PageErr />} key={location.pathname}>
      {showSidebar ? (
        <>
          <SCPageWrapper>
            <SCPageWrapperContent>
              <Routes />
            </SCPageWrapperContent>
            <SCPageWrapperSidebar>
              <div
                onClick={() => {
                  setShowSidebar(false)
                }}
                style={{ position: 'absolute', top: 10, right: 25, zIndex: 1000 }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
              <SCPageWrapperSidebarTitle>MainStem SideKick™</SCPageWrapperSidebarTitle>
              <SCPageWrapperSidebarContent>
                <SCPageWrapperSidebarMessages ref={messagesViewRef}>
                  <SCPageWrapperSidebarMessage>
                    Hello! I am MainStem SideKick. I am here to help you with any questions you may have. Please feel
                    free to ask me anything.
                    <br />
                    <br />I will also provide you with helpful tips and tricks to make your experience on MainStem even
                    better.
                  </SCPageWrapperSidebarMessage>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <div>
                      <Button color='fancy' icon={faMessage}>
                        <span>Ask MainStem SideKick a question</span>
                      </Button>
                    </div>
                    <div>
                      <Button
                        color='fancy'
                        icon={faPaperclipVertical}
                        onClick={() => {
                          setShowModalQuickOrder(true)
                        }}
                      >
                        <span>Give MainStem SideKick A URL</span>
                      </Button>
                    </div>
                  </div>
                  {messages.map((message, index) => {
                    return (
                      <SCPageWrapperSidebarMessage
                        key={index}
                        style={{
                          backgroundColor: message.type === 'info' ? '#FFFFFF' : '#edf5fd',
                          border: '1px solid #e8e8e8',
                          padding: 10,
                          margin: '10px 0px'
                        }}
                      >
                        {message.message}
                      </SCPageWrapperSidebarMessage>
                    )
                  })}
                  {loadingConversation && (
                    <>
                      <SCPageWrapperSidebarMessage>
                        <Loader size='sm' />
                      </SCPageWrapperSidebarMessage>
                    </>
                  )}
                </SCPageWrapperSidebarMessages>
                <SCPageWrapperSidebarTextArea>
                  <InputText
                    inputStyle={{
                      borderRadius: 8,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0
                    }}
                    onChange={(newValue) => {
                      setMessageText(newValue)
                    }}
                    onEnter={() => {
                      getConversation()
                    }}
                    placeholder='Ask a question or paste a URL...'
                    value={messageText}
                  />
                  <div
                    style={{
                      background: 'radial-gradient(106.04% 106.06% at 100.1% 90.19%,#0f6cbd 33.63%,#8dddd8 100%)',
                      height: 5,
                      width: '100%',
                      marginTop: -16,
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8
                    }}
                  />
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 10, marginTop: 5 }}>
                    <div>
                      <Button
                        color='primary'
                        onClick={() => {
                          getConversation()
                        }}
                        small
                        tooltip='Send Message'
                      >
                        <FontAwesomeIcon icon={faMessage} />
                      </Button>
                    </div>
                    <div>
                      <Button
                        color='primary'
                        onClick={() => {
                          setShowModalQuickOrder(true)
                        }}
                        small
                        tooltip='Paste A URL'
                      >
                        <FontAwesomeIcon icon={faPaperclipVertical} />
                      </Button>
                    </div>
                    <div>
                      <Button
                        color='primary'
                        onClick={() => {
                          setMessages([])
                        }}
                        small
                        tooltip='Clear Messages'
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </div>
                </SCPageWrapperSidebarTextArea>
              </SCPageWrapperSidebarContent>
            </SCPageWrapperSidebar>
          </SCPageWrapper>
          {showModalQuickOrder && (
            <Modal onClose={() => setShowModalQuickOrder(false)}>
              <ModalHeader>Give MainStem SideKick™ A URL</ModalHeader>
              <ModalBody>
                <InputText
                  onChange={(newValue) => {
                    setURL(newValue)
                  }}
                  onEnter={() => {
                    getProductData()
                  }}
                  placeholder='Enter URL here: https://www.example.com'
                  value={url}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  block
                  color='fancy'
                  icon={faPaperclipVertical}
                  loading={loading}
                  onClick={() => {
                    getProductData()
                  }}
                >
                  Submit URL &amp; Use MainStem SideKick™ AI To Analyze...
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </>
      ) : (
        <>
          {showSideKick && (
            <div
              onClick={() => {
                setShowSidebar(true)
              }}
              style={{
                position: 'absolute',
                top: -10,
                right: 10,
                backgroundColor: '#FAFAFA',
                borderRadius: '50%',
                border: '1px solid #e8e8e8',
                padding: 5,
                zIndex: 1000
              }}
            >
              <FontAwesomeIcon icon={faMessageBot} />
            </div>
          )}
          <Routes />
        </>
      )}
    </ErrorBoundary>
  )
}

export { ErrorWrapper }
