// React
import { useState } from 'react'
// MainStem - UI
import { Button, Loader, Modal, ModalBody, SelectSingle, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { IModalSelectCatalogSingle, ISelectCatalogOption } from './types'

const ModalSelectCatalogSingle = ({ onClose, onCatalogSelected }: IModalSelectCatalogSingle) => {
  // Loading Indicators
  const [loadingCatalogs, setLoadingCatalogs] = useState<boolean>(false)
  // View State
  const [selectOptions, setSelectOptions] = useState<ISelectCatalogOption[] | undefined>(undefined)
  const [selectedCatalog, setSelectedCatalog] = useState<ISelectCatalogOption | undefined>(undefined)

  const loadCatalogs = () => {
    setLoadingCatalogs(true)

    mainStemApi.api
      .catalogList()
      .then((apiResponse) => {
        const formattedSelectOptions = apiResponse.data.catalogs?.map((item) => {
          return { value: item.id, label: item.name } as ISelectCatalogOption
        })
        setSelectOptions(formattedSelectOptions)
      })
      .finally(() => {
        setLoadingCatalogs(false)
      })
  }

  useOnFirstLoad(() => {
    loadCatalogs()
  })

  return (
    <Modal onClose={onClose} scrollable={false}>
      <ModalBody>
        <h5
          style={{
            color: '#333',
            fontFamily: 'Poppins, sans-serif',
            fontSize: 16,
            fontWeight: 700,
            lineHeight: 1,
            marginBottom: 25,
            textAlign: 'center',
            textTransform: 'uppercase'
          }}
        >
          Select Catalog
        </h5>
        {loadingCatalogs ? (
          <Loader />
        ) : (
          <SelectSingle
            onChange={(newValue) => setSelectedCatalog(newValue)}
            options={selectOptions || []}
            placeholder='Select a catalog...'
            value={selectedCatalog}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
            justifyContent: 'center',
            marginTop: 25
          }}
        >
          <Button color='danger' onClick={onClose} style={{ minWidth: 150 }}>
            Cancel
          </Button>
          <Button
            color='primary'
            disabled={!selectedCatalog}
            onClick={() => {
              if (typeof onCatalogSelected === 'function') {
                onCatalogSelected(selectedCatalog)
              }
            }}
            style={{ minWidth: 150 }}
          >
            Select Catalog
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export { ModalSelectCatalogSingle }
