import { HTTP } from 'config'
import { LeafLogixVendorListRequest } from './request'

export async function APIIntegrationsLeafLogixVendorsList(apiRequest: LeafLogixVendorListRequest): Promise<any>  {
  let apiResponse = {} as any

  await HTTP.post('integrations/leafLogix/vendors/list', apiRequest).then((response: { status: number; data: any }) => {
    if (response.status === 200) {
      apiResponse = response.data
    }
  })

  return apiResponse
}
