import 'react-modern-drawer/dist/index.css'
// React
import { useState } from 'react'
// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import { Button } from '@mainstem/react-mainstem'
// React Drawer
import Drawer from 'react-modern-drawer'
// Icons
import { faFileCertificate, faRobot, faRunning } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { ButtonGoBack, ModalQuickRFQ } from 'components'
// Global - Config
import { copilotURL } from 'config'
// Local - Components
import { SearchLive } from './components'

const NavTopButtons: React.FC = () => {
  // Routing
  const history = useHistory()
  // Modal
  const [showModalQuickRFQ, setShowModalQuickRFQ] = useState(false)
  const [showCopilot, setShowCopilot] = useState(false)
  const showCopilotButton = false
  const showQuickOrderButton = false

  return (
    <>
      <ButtonGoBack />
      <Button
        color='dark'
        icon={faFileCertificate}
        onClick={() => {
          setShowModalQuickRFQ(true)
        }}
      >
        Quick RFQ™
      </Button>
      {showQuickOrderButton && (
        <>
          <Button
            color='dark'
            icon={faRunning}
            onClick={() => {
              history.push('/requisitions/quick-order')
            }}
          >
            Quick Order
          </Button>
        </>
      )}
      {showCopilotButton && (
        <Button
          color='dark'
          icon={faRobot}
          onClick={() => {
            setShowCopilot(true)
          }}
        >
          MainStem Copilot
        </Button>
      )}
      <div style={{ minWidth: '550px', width: '100%' }}>
        <SearchLive />
      </div>
      {showModalQuickRFQ && (
        <ModalQuickRFQ
          onClose={() => {
            setShowModalQuickRFQ(false)
          }}
        />
      )}
      {showCopilot && (
        <>
          <Drawer
            direction='right'
            onClose={() => {
              setShowCopilot(false)
            }}
            open={showCopilot}
            style={{ boxShadow: '-2px 0px 10px #c8c8c8 !important', width: '50%' }}
          >
            <div style={{ height: '100%', width: '100%' }}>
              <iframe src={copilotURL} style={{ border: '0px none', height: '100%', width: '100%' }} />
            </div>
          </Drawer>
        </>
      )}
    </>
  )
}

export default NavTopButtons
