import { APIIntegrationsLeafLogixVendorsList } from 'api/integrations/leaf-logix/vendors/list'
export const loadLeafLogixVendors = (
  customerIntegrationSettingID: number,
  loadingSetter: any,
  setter: any
) => {
  const apiRequest = {
    customerIntegrationSettingID: customerIntegrationSettingID
  }
  loadingSetter(true)
  return APIIntegrationsLeafLogixVendorsList(apiRequest).then((response) => {
    const mappedVendors = response.map((vendor: any) => {
      return {
        ...vendor,
        value: vendor.vendorId,
        label: `[${vendor.vendorId}] ${vendor.vendorName}`
      }
    })
    setter(mappedVendors)
    loadingSetter(false)
    return mappedVendors
  })
}
