// React
import { useContext, useEffect } from 'react'
// Microsoft - Application Insights
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js'
// Helmet
import { Helmet } from 'react-helmet'
// Routing
import { useLocation } from 'react-router'
// Global - Config
import { defaultTitle } from 'config'
// Global - Context
import { AppContext } from 'context'

/**
 * Resets the page's title to a default title anytime the route changes.
 * This helps when a page sets its title to something static, that when a user leaves the page we can reset the title.
 */
const DefaultTitle: React.FC = () => {
  // Routing
  const location = useLocation()
  // Context
  const { loggedInUser } = useContext(AppContext)
  // App Insights
  const appInsights = useAppInsightsContext()
  const trackGetUser = useTrackEvent(appInsights, 'Get User', {})

  useEffect(() => {
    if (loggedInUser) {
      trackGetUser(loggedInUser)
    }
  }, [loggedInUser])

  return (
    <>
      <Helmet key={location.pathname}>
        <title>{defaultTitle}</title>
      </Helmet>
    </>
  )
}
export { DefaultTitle }
