// React
import { useState } from 'react'
// MainStem - UI
import {
  Button,
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectMulti,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
import { SelectMultiValue } from '@mainstem/react-mainstem/dist/components/Form/Select/Multi/types'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { IModalSelectUsers } from './types'

const ModalSelectUsers: React.FC<IModalSelectUsers> = ({ onClose, onUsersSelected }: IModalSelectUsers) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [selectOptions, setSelectOptions] = useState<ISelectOption[]>([])
  const [selectedUsers, setSelectedUsers] = useState<SelectMultiValue>()

  const loadUsers = () => {
    setLoading(true)

    mainStemApi.api
      .usersList()
      .then((apiResponse) => {
        if (apiResponse.data.users) {
          const formattedSelectOptions = apiResponse.data.users.map((user) => {
            return {
              value: user.uuid,
              label: user.username
            } as ISelectOption
          })
          setSelectOptions(formattedSelectOptions)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useOnFirstLoad(() => {
    loadUsers()
  })

  return (
    <Modal
      onClose={() => {
        onClose()
      }}
      scrollable={false}
    >
      <ModalBody>
        <ModalHeader>Select Users</ModalHeader>
        {loading ? (
          <Loader />
        ) : (
          <SelectMulti
            isClearable
            onChange={(value) => {
              setSelectedUsers(value)
              onUsersSelected(value)
            }}
            options={selectOptions}
            value={selectedUsers}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, gap: 10 }}>
          <Button color='danger' onClick={onClose} style={{ minWidth: 150 }}>
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => {
              onUsersSelected(selectedUsers)
            }}
            style={{ minWidth: 150 }}
          >
            Select Users
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export { ModalSelectUsers }
