// Local - TypeScript Types
import { IAPIAuthLogoutResponse } from './response'

export async function APIAuthLogout(): Promise<IAPIAuthLogoutResponse> {
  // Prepare our response
  const apiResponse = {
    wasSuccessful: false
  } as IAPIAuthLogoutResponse
  // Remove the token from localStorage
  window.localStorage.removeItem('mainstem_user_token')
  // Set our response to be successful
  apiResponse.wasSuccessful = true
  // Return with our response
  return apiResponse
}
