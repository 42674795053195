// React
import { Suspense, lazy } from 'react'
// Routing
import { Redirect, Route, Switch } from 'react-router-dom'
// MainStem - UI
import { Loader } from '@mainstem/react-mainstem'
// Page : Error
import PageErr from 'pages/err'
// Lazy Loaded Pages
const lazyLoadedPages = {
  accounting: {
    pay: {
      invoice: lazy(() => import('pages/accounting/pay/invoice'))
    }
  },
  amazon: {
    product: {
      details: lazy(() => import('pages/amazon/product/details'))
    }
  },
  logistics: {
    receiving: {
      dashboard: lazy(() => import('pages/logistics/receiving/dashboard')),
      details: lazy(() => import('pages/logistics/receiving/details'))
    },
    shipment: {
      details: lazy(() => import('pages/logistics/shipment/details')),
      dashboard: lazy(() => import('pages/logistics/shipment/dashboard'))
    }
  },
  organization: {
    catalog: {
      create: lazy(() => import('pages/organization/catalog/create')),
      dashboard: lazy(() => import('pages/organization/catalog/dashboard')),
      details: lazy(() => import('pages/organization/catalog/details')),
      update: lazy(() => import('pages/organization/catalog/update'))
    },
    department: {
      create: lazy(() => import('pages/organization/department/create')),
      dashboard: lazy(() => import('pages/organization/department/dashboard')),
      details: lazy(() => import('pages/organization/department/details')),
      update: lazy(() => import('pages/organization/department/update'))
    },
    location: {
      create: lazy(() => import('pages/organization/location/create')),
      dashboard: lazy(() => import('pages/organization/location/dashboard')),
      details: lazy(() => import('pages/organization/location/details')),
      update: lazy(() => import('pages/organization/location/update'))
    },
    permissionGroup: {
      dashboard: lazy(() => import('pages/organization/permission-group/dashboard')),
      details: lazy(() => import('pages/organization/permission-group/details')),
      update: lazy(() => import('pages/organization/permission-group/update'))
    },
    user: {
      create: lazy(() => import('pages/organization/user/create')),
      details: lazy(() => import('pages/organization/user/details')),
      dashboard: lazy(() => import('pages/organization/user/dashboard')),
      update: lazy(() => import('pages/organization/user/update'))
    }
  },
  purchasing: {
    invoices: {
      dashboard: lazy(() => import('pages/purchasing/invoices/dashboard')),
      inquiry: lazy(() => import('pages/purchasing/invoices/inquiry'))
    },
    punchOut: {
      details: lazy(() => import('pages/purchasing/punch-out/details')),
      purchase: lazy(() => import('pages/purchasing/punch-out/purchase')),
      settings: lazy(() => import('pages/purchasing/punch-out/settings'))
    },
    purchaseOrders: {
      dashboard: lazy(() => import('pages/purchasing/purchase-orders/dashboard'))
    },
    requisitions: {
      dashboard: lazy(() => import('pages/purchasing/requisition/dashboard')),
      details: lazy(() => import('pages/purchasing/requisition/details')),
      quickorder: lazy(() => import('pages/purchasing/requisition/quick-order')),
      recommendation: lazy(() => import('pages/purchasing/requisition/recommendation')),
      submit: lazy(() => import('pages/purchasing/requisition/submit'))
    },
    rfq: {
      create: lazy(() => import('pages/purchasing/rfq/create')),
      dashboard: lazy(() => import('pages/purchasing/rfq/dashboard')),
      details: lazy(() => import('pages/purchasing/rfq/details'))
    }
  },
  settings: {
    account: lazy(() => import('pages/settings/account')),
    feed: {
      create: lazy(() => import('pages/settings/feed/create')),
      details: lazy(() => import('pages/settings/feed/details')),
      update: lazy(() => import('pages/settings/feed/update'))
    },
    integration: {
      dashboard: lazy(() => import('pages/settings/integration/dashboard')),
      dutchie: {
        details: lazy(() => import('pages/settings/integration/dutchie/details')),
        gate: lazy(() => import('pages/settings/integration/dutchie/gate'))
      },
      email: lazy(() => import('pages/settings/integration/email')),
      excel: lazy(() => import('pages/settings/integration/excel')),
      leafLogix: lazy(() => import('pages/settings/integration/leaflogix')),
      powerBI: lazy(() => import('pages/settings/integration/power-bi')),
      powerQuery: lazy(() => import('pages/settings/integration/power-query')),
      quickBooksOnline: {
        details: lazy(() => import('pages/settings/integration/quickbooks-online/details')),
        gate: lazy(() => import('pages/settings/integration/quickbooks-online/gate'))
      },
      quantumLeaf: {
        details: lazy(() => import('pages/settings/integration/quantum-leaf/details')),
        gate: lazy(() => import('pages/settings/integration/quantum-leaf/gate'))
      }
    }
  },
  supplyChain: {
    product: {
      create: lazy(() => import('pages/supply-chain/product/create')),
      dashboard: lazy(() => import('pages/supply-chain/product/dashboard')),
      details: lazy(() => import('pages/supply-chain/product/details')),
      update: lazy(() => import('pages/supply-chain/product/update'))
    },
    supplier: {
      create: lazy(() => import('pages/supply-chain/supplier/create')),
      dashboard: {
        route: '/suppliers/dashboard',
        page: lazy(() => import('pages/supply-chain/supplier/dashboard'))
      },
      details: lazy(() => import('pages/supply-chain/supplier/details')),
      update: lazy(() => import('pages/supply-chain/supplier/update'))
    }
  },
  blog: {
    marketplace: lazy(() => import('pages/blog/marketplace')),
    marketplaceNew: lazy(() => import('pages/blog/marketplace/new')),
    orderManagement: lazy(() => import('pages/blog/order-management'))
  },
  dashboard: {
    approval: lazy(() => import('pages/dashboard/approval')),
    budgets: lazy(() => import('pages/dashboard/budgets')),
    custom: lazy(() => import('pages/dashboard/custom')),
    finance: lazy(() => import('pages/dashboard/finance')),
    marketplace: lazy(() => import('pages/dashboard/marketplace')),
    purchasing: lazy(() => import('pages/dashboard/purchasing'))
  },

  onboard: lazy(() => import('pages/onboard')),

  report: {
    accruals: lazy(() => import('pages/report/accruals')),
    orderHistory: lazy(() => import('pages/report/order-history')),
    lineItems: lazy(() => import('pages/report/line-items')),
    unfulfilledRequisitions: lazy(() => import('pages/report/unfulfilled-requisitions')),
    punchOuts: lazy(() => import('pages/report/punch-outs'))
  },

  search: lazy(() => import('pages/search')),
  shop: lazy(() => import('pages/shop'))
}

export const Routes = () => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {/* Default - Route */}
      <Route exact path='/'>
        <Redirect to='/dashboard/marketplace' />
      </Route>
      {/* Account */}
      <Route exact path='/account'>
        <lazyLoadedPages.settings.account />
      </Route>
      {/* Accounting */}
      <Route exact path='/accounting/pay/invoice'>
        <lazyLoadedPages.accounting.pay.invoice />
      </Route>
      {/* Amazon */}
      <Route exact path='/amazon/product/:asin'>
        <lazyLoadedPages.amazon.product.details />
      </Route>
      {/* Blog */}
      <Route exact path='/blog/marketplace-1.5'>
        <lazyLoadedPages.blog.marketplace />
      </Route>
      <Route exact path='/blog/marketplace-1.5/welcome'>
        <lazyLoadedPages.blog.marketplaceNew />
      </Route>
      <Route exact path='/blog/order-management'>
        <lazyLoadedPages.blog.orderManagement />
      </Route>
      {/* Catalogs */}
      <Route exact path='/catalogs/create'>
        <lazyLoadedPages.organization.catalog.create />
      </Route>
      <Route exact path='/catalogs/dashboard'>
        <lazyLoadedPages.organization.catalog.dashboard />
      </Route>
      <Route exact path='/catalogs/details/:id'>
        <lazyLoadedPages.organization.catalog.details />
      </Route>
      <Route exact path='/catalogs/browser/:id?'>
        <lazyLoadedPages.shop />
      </Route>
      <Route exact path='/catalogs/update/:id'>
        <lazyLoadedPages.organization.catalog.update />
      </Route>
      {/* Dashboards */}
      <Route exact path='/dashboard/approval'>
        <lazyLoadedPages.dashboard.approval />
      </Route>
      <Route exact path='/dashboard/budgets'>
        <lazyLoadedPages.dashboard.budgets />
      </Route>
      <Route exact path='/dashboard/custom'>
        <lazyLoadedPages.dashboard.custom />
      </Route>
      <Route exact path='/dashboard/finance'>
        <lazyLoadedPages.dashboard.finance />
      </Route>
      <Route exact path='/dashboard/marketplace'>
        <lazyLoadedPages.dashboard.marketplace />
      </Route>
      <Route exact path='/dashboard/purchasing'>
        <lazyLoadedPages.dashboard.purchasing />
      </Route>
      {/* Departments */}
      <Route exact path='/departments/create/:locationId?'>
        <lazyLoadedPages.organization.department.create />
      </Route>
      <Route exact path='/departments/dashboard'>
        <lazyLoadedPages.organization.department.dashboard />
      </Route>
      <Route exact path='/departments/details/:id'>
        <lazyLoadedPages.organization.department.details />
      </Route>
      <Route exact path='/departments/update/:id'>
        <lazyLoadedPages.organization.department.update />
      </Route>
      {/* Feed */}
      <Route exact path='/feed/create'>
        <lazyLoadedPages.settings.feed.create />
      </Route>
      <Route exact path='/feed/details/:id'>
        <lazyLoadedPages.settings.feed.details />
      </Route>
      <Route exact path='/feed/update/:id'>
        <lazyLoadedPages.settings.feed.update />
      </Route>
      {/* Integrations */}
      <Route exact path='/integrations/dashboard'>
        <lazyLoadedPages.settings.integration.dashboard />
      </Route>
      {/* Integrations - Dutchie */}
      <Route exact path='/integrations/dutchie/details/:id'>
        <lazyLoadedPages.settings.integration.dutchie.details />
      </Route>
      <Route exact path='/integrations/dutchie/gate'>
        <lazyLoadedPages.settings.integration.dutchie.gate />
      </Route>
      {/* Integrations - Email */}
      <Route exact path='/integrations/email'>
        <lazyLoadedPages.settings.integration.email />
      </Route>
      {/* Integrations - Excel */}
      <Route exact path='/integrations/excel'>
        <lazyLoadedPages.settings.integration.excel />
      </Route>
      {/* Integrations - LeafLogix */}
      <Route exact path='/integrations/leaflogix'>
        <lazyLoadedPages.settings.integration.leafLogix />
      </Route>
      {/* Integrations - PowerBI */}
      <Route exact path='/integrations/power-bi'>
        <lazyLoadedPages.settings.integration.powerBI />
      </Route>
      {/* Integrations - Power Query */}
      <Route exact path='/integrations/power-query'>
        <lazyLoadedPages.settings.integration.powerQuery />
      </Route>
      {/* Integrations - QuantumLeaf */}
      <Route exact path='/integrations/quantum-leaf/details/:id'>
        <lazyLoadedPages.settings.integration.quantumLeaf.details />
      </Route>
      <Route exact path='/integrations/quantum-leaf/gate'>
        <lazyLoadedPages.settings.integration.quantumLeaf.gate />
      </Route>
      {/* Integrations - QuickBooks - Online */}
      <Route exact path='/integrations/quickbooks-online/gate'>
        <lazyLoadedPages.settings.integration.quickBooksOnline.gate />
      </Route>
      <Route exact path='/integrations/quickbooks-online/details/:id'>
        <lazyLoadedPages.settings.integration.quickBooksOnline.details />
      </Route>
      {/* Invoices */}
      <Route exact path='/invoices/dashboard'>
        <lazyLoadedPages.purchasing.invoices.dashboard />
      </Route>
      {/* Invoice Inquiry */}
      <Route exact path='/invoice-inquiry/details/:id'>
        <lazyLoadedPages.purchasing.invoices.inquiry />
      </Route>
      {/* Location */}
      <Route exact path='/locations/create'>
        <lazyLoadedPages.organization.location.create />
      </Route>
      <Route exact path='/locations/dashboard/:action?'>
        <lazyLoadedPages.organization.location.dashboard />
      </Route>
      <Route exact path='/locations/details/:id'>
        <lazyLoadedPages.organization.location.details />
      </Route>
      <Route exact path='/locations/update/:id'>
        <lazyLoadedPages.organization.location.update />
      </Route>
      {/* Onboard */}
      <Route exact path='/onboard/:walkThrough?'>
        <lazyLoadedPages.onboard />
      </Route>
      {/* Requisition */}
      <Route exact path='/requisitions/dashboard'>
        <lazyLoadedPages.purchasing.requisitions.dashboard />
      </Route>
      <Route path={'/requisitions/details/:uuid/:paymentStatus'}>
        <lazyLoadedPages.purchasing.requisitions.details />
      </Route>
      <Route path={'/requisitions/details/:uuid'}>
        <lazyLoadedPages.purchasing.requisitions.details />
      </Route>
      {/* DEPRECATE : Order Details Route */}
      <Route path={'/orders/details/:uuid'}>
        <lazyLoadedPages.purchasing.requisitions.details />
      </Route>
      <Route exact path='/requisitions/quick-order'>
        <lazyLoadedPages.purchasing.requisitions.quickorder />
      </Route>
      <Route exact path='/requisition/recommendation/:id'>
        <lazyLoadedPages.purchasing.requisitions.recommendation />
      </Route>
      <Route exact path='/requisitions/submit/:uuid'>
        <lazyLoadedPages.purchasing.requisitions.submit />
      </Route>
      {/* Permission Group */}
      <Route exact path='/permission-group/dashboard'>
        <lazyLoadedPages.organization.permissionGroup.dashboard />
      </Route>
      <Route exact path='/permission-group/details/:id'>
        <lazyLoadedPages.organization.permissionGroup.details />
      </Route>
      <Route exact path='/permission-group/update/:id'>
        <lazyLoadedPages.organization.permissionGroup.update />
      </Route>
      {/* Product */}
      <Route exact path='/products/create/:supplierId?'>
        <lazyLoadedPages.supplyChain.product.create />
      </Route>
      <Route exact path='/products/dashboard'>
        <lazyLoadedPages.supplyChain.product.dashboard />
      </Route>
      <Route exact path='/products/details/:id'>
        <lazyLoadedPages.supplyChain.product.details />
      </Route>
      <Route exact path='/products/update/:id'>
        <lazyLoadedPages.supplyChain.product.update />
      </Route>
      {/* Punch Out */}
      <Route path={'/punch-outs/details/:id'}>
        <lazyLoadedPages.purchasing.punchOut.details />
      </Route>
      <Route path={'/punch-outs/details/:id/:action'}>
        <lazyLoadedPages.purchasing.punchOut.details />
      </Route>
      <Route exact path='/punch-outs/purchase'>
        <lazyLoadedPages.purchasing.punchOut.purchase />
      </Route>
      <Route exact path='/punch-outs/settings'>
        <lazyLoadedPages.purchasing.punchOut.settings />
      </Route>
      {/* Purchase Orders */}
      <Route exact path='/purchase-orders/dashboard'>
        <lazyLoadedPages.purchasing.purchaseOrders.dashboard />
      </Route>
      {/* Receiving */}
      <Route exact path='/receiving/dashboard'>
        <lazyLoadedPages.logistics.receiving.dashboard />
      </Route>
      <Route exact path='/receiving/details/:id?'>
        <lazyLoadedPages.logistics.receiving.details />
      </Route>
      {/* Report */}
      <Route exact path='/reports/accruals'>
        <lazyLoadedPages.report.accruals />
      </Route>
      <Route exact path='/reports/historical-orders'>
        <lazyLoadedPages.report.orderHistory />
      </Route>
      <Route exact path='/reports/order-history'>
        <lazyLoadedPages.report.orderHistory />
      </Route>
      <Route exact path='/reports/line-items'>
        <lazyLoadedPages.report.lineItems />
      </Route>
      <Route exact path='/reports/unfulfilled-requisitions'>
        <lazyLoadedPages.report.unfulfilledRequisitions />
      </Route>
      <Route exact path='/reports/open-orders'>
        <lazyLoadedPages.report.unfulfilledRequisitions />
      </Route>
      <Route exact path='/reports/punch-outs'>
        <lazyLoadedPages.report.punchOuts />
      </Route>
      {/* RFQ */}
      <Route exact path='/rfq/create'>
        <lazyLoadedPages.purchasing.rfq.create />
      </Route>
      <Route exact path='/rfq/dashboard'>
        <lazyLoadedPages.purchasing.rfq.dashboard />
      </Route>
      <Route exact path='/rfq/details/:id'>
        <lazyLoadedPages.purchasing.rfq.details />
      </Route>
      {/* Search */}
      <Route exact path='/search/:query?'>
        <lazyLoadedPages.search />
      </Route>
      {/* Shipment */}
      <Route exact path='/shipments/dashboard'>
        <lazyLoadedPages.logistics.shipment.dashboard />
      </Route>
      <Route exact path='/shipments/details/:id'>
        <lazyLoadedPages.logistics.shipment.details />
      </Route>
      {/* Shop */}
      <Route exact path='/shop'>
        <lazyLoadedPages.shop />
      </Route>
      {/* Supplier */}
      <Route exact path='/suppliers/create'>
        <lazyLoadedPages.supplyChain.supplier.create />
      </Route>
      <Route exact path={lazyLoadedPages.supplyChain.supplier.dashboard.route}>
        <lazyLoadedPages.supplyChain.supplier.dashboard.page />
      </Route>
      <Route exact path='/suppliers/details/:id'>
        <lazyLoadedPages.supplyChain.supplier.details />
      </Route>
      <Route exact path='/suppliers/update/:id'>
        <lazyLoadedPages.supplyChain.supplier.update />
      </Route>
      {/* User */}
      <Route exact path='/users/create'>
        <lazyLoadedPages.organization.user.create />
      </Route>
      <Route exact path='/users/dashboard'>
        <lazyLoadedPages.organization.user.dashboard />
      </Route>
      <Route exact path='/users/details/:uuid'>
        <lazyLoadedPages.organization.user.details />
      </Route>
      <Route exact path='/users/update/:uuid'>
        <lazyLoadedPages.organization.user.update />
      </Route>
      {/* 404 / Route Not Found */}
      <Route>
        <PageErr />
      </Route>
    </Switch>
  </Suspense>
)
