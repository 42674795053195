// Routing
import { useHistory } from 'react-router-dom'
// MainStem
import { Button, ButtonGroup, Card, CardBody, theme } from '@mainstem/react-mainstem'
// Icons
import { faChevronLeft, faHome } from '@fortawesome/pro-light-svg-icons'
// Local - Styled Components
import { SCErrorContent, SCErrorWrapper } from './styles'

const PageErr: React.FC = () => {
  const history = useHistory()

  const handleGoBack = () => {
    history.go(-1)
    window.location.reload()
  }

  const handleGoHome = () => {
    history.push('/')
    window.location.reload()
  }

  return (
    <SCErrorWrapper>
      <SCErrorContent>
        <img alt='Sad Panda' src={theme.images.panda.sad} style={{ height: '100px' }} />
        <hr className='mt-0' />
        <Card>
          <CardBody topPadding>
            <div className='text-center'>
              <h1>Uh Oh...</h1>
              <p>It looks like something did not go as expected.</p>
              <p>Our team has been notified about this error and we will look into it right away!</p>
            </div>
            <ButtonGroup style={{ width: '100%' }}>
              <Button block color='secondary' icon={faHome} onClick={handleGoHome}>
                <span>Go Home</span>
              </Button>
              <Button block color='primary' icon={faChevronLeft} onClick={handleGoBack}>
                Go Back
              </Button>
            </ButtonGroup>
          </CardBody>
        </Card>
      </SCErrorContent>
    </SCErrorWrapper>
  )
}

export default PageErr
