// MainStem - API
import { Api } from 'api-new'
// Global - Types
import { LeafLogixTypes } from 'types'
// Global - Helpers
import { LeafLogixTypeAsString } from 'helpers'

const mainStemAPI = new Api()

export const loadLeafLogixInventoryDetail = (
  customerIntegrationSettingIDs: number[],
  setLoading: any,
  setter: any,
  type?: LeafLogixTypes
) => {
  setLoading(true)
  return loadInventoryByIntegrationID(customerIntegrationSettingIDs, type)
    .then((response) => {
      setter(response?.detail)
      return response
    })
    .catch((error) => {
      return error
    })
    .finally(() => {
      setLoading(false)
    })
}

export const loadLeafLogixAggregateForRecord = (
  customerIntegrationSettingIDs: number[],
  recordID: number,
  type: LeafLogixTypes,
  setLoading: any,
  setter: any
) => {
  setLoading(true)
  return loadInventoryByIntegrationID(customerIntegrationSettingIDs, type, recordID)
    .then((response) => {
      let inventory = {
        recordID: recordID,
        inventory: []
      } as any
      if (!response) {
        return inventory
      }
      // All matches are using == value matches, not === type matches
      switch (type) {
        case LeafLogixTypes.Rooms:
          {
            const foundMatch = response.inventoryByDepartment?.find((x: { recordID: number }) => x.recordID == recordID)
            inventory = foundMatch ?? inventory
          }
          break
        case LeafLogixTypes.Products:
          {
            const foundMatch = response.inventoryByProduct?.find((x: { recordID: number }) => x.recordID == recordID)
            inventory = foundMatch ?? inventory
          }
          break
        case LeafLogixTypes.Vendors:
          {
            const foundMatch = response.inventoryBySupplier?.find((x: { recordID: number }) => x.recordID == recordID)
            inventory = foundMatch ?? inventory
          }
          break
        default:
          break
      }
      setter(inventory)
      return inventory
    })
    .catch((error) => {
      return error
    })
    .finally(() => {
      setLoading(false)
    })
}

export const loadLeafLogixAggregates = (
  customerIntegrationSettingIDs: number[],
  recordID: number,
  type: LeafLogixTypes,
  setLoading: any,
  setter: any
) => {
  setLoading(true)
  return loadInventoryByIntegrationID(customerIntegrationSettingIDs, type, recordID)
    .then((response) => {
      setter(response)
      return response
    })
    .catch((error) => {
      return error
    })
    .finally(() => {
      setLoading(false)
    })
}

export const loadInventoryByIntegrationID = (
  customerIntegrationSettingIDs: number[],
  type?: LeafLogixTypes,
  recordID?: number
) => {
  return mainStemAPI.api
    .integrationLeafLogixInventoryList({
      customerIntegrationSettingIDs: customerIntegrationSettingIDs,
      type: type ? LeafLogixTypeAsString(type) : undefined,
      recordID: recordID
    })
    .then((response) => {
      const responseData = response.data
      return responseData ?? response
    })
    .catch((error) => {
      return error
    })
}
