import { APIIntegrationsLeafLogixRoomsList } from "api/integrations/leaf-logix/rooms/list"
import { LeafLogixRoomListRequest } from "api/integrations/leaf-logix/rooms/list/request"


  export const loadLeafLogixRooms = (
    customerIntegrationSettingID: number,
    loadSetter: any,
    setter: any
  ) => {
    const apiRequest = {
      customerIntegrationSettingID: customerIntegrationSettingID
    } as LeafLogixRoomListRequest
    loadSetter(true)
    return APIIntegrationsLeafLogixRoomsList(apiRequest).then((response) => {
      const mappedRooms = response.map((room: any) => {
        return {
          ...room,
          value: room.roomId,
          label: `[${room.roomId}] ${room.roomName}`
        }
      })
      setter(mappedRooms)
      loadSetter(false)
      return mappedRooms
    })
  }