import styled from 'styled-components'

export const SCPageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 165px);
  gap: 15px;
`

export const SCPageWrapperContent = styled.div`
  height: auto;
  flex: 1;
  overflow-y: auto;
`

export const SCPageWrapperSidebar = styled.div`
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0px 0px 4px #312fa1;
  height: 100%;
  overflow: hidden;
  width: 320px;
`

export const SCPageWrapperSidebarTitle = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  color: #242424;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: sticky;
  text-align: center;
  width: 100%;
`

export const SCPageWrapperSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const SCPageWrapperSidebarMessages = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 15px;
  ::-webkit-scrollbar {
    width: 4px;
    background: #ecebeb;
    box-shadow: none;
    z-index: 9999;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b0adad;
    height: 8px;
    border-radius: 8px;
    box-shadow: none;
  }
`

export const SCPageWrapperSidebarTextArea = styled.div`
  height: 150px;
  padding: 15px;
`

export const SCPageWrapperSidebarMessage = styled.div`
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
  margin-bottom: 10px;
`
