import './assets/scss/pretty-leaflet-map.scss'
import { Fragment, useEffect, useState } from 'react'
import L from 'leaflet'
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import TextPath from 'react-leaflet-textpath'
// API's
import { SCLeafletMapStyleContainer } from './styles'
import { cdnURL, formattedNumberAsString } from '@mainstem/react-mainstem'
import { apiAddressGetLatLon } from 'api/global/address/getLatLon'

// Local Component Assets
const imgMapPin = `${cdnURL}/apps/lib/components/Pretty/PrettyLeafletMap/map-pin.png`
const imgMapPinShadow = `${cdnURL}/apps/lib/components/Pretty/PrettyLeafletMap/assets/img/map-pin-shadow.png`

// Set our custom map pin icons
L.Icon.Default.mergeOptions({
  iconRetinaUrl: imgMapPin,
  iconUrl: imgMapPin,
  shadowUrl: imgMapPinShadow
})

/* =============================================================================
                                PrettyLeafletMap
============================================================================= */

const LeafletMap: React.FC<any> = ({ height, markers, center, autoOpenMarkers, onMarkerClicked }) => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [doneLoadingData, setDoneLoadingData] = useState<boolean>(false)
  const [leafletMarkers, setLeafletMarkers] = useState<any>([])
  const [map, setMap] = useState<any>()

  /* ======================
        distance()
  ====================== */

  function distance(lat1: any, lon1: any, lat2: any, lon2: any) {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0
    }
    const radlat1 = (Math.PI * lat1) / 180
    const radlat2 = (Math.PI * lat2) / 180
    const theta = lon1 - lon2
    const radtheta = (Math.PI * theta) / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    return dist
  }

  /* ======================
        initMarker
  ====================== */

  const initMarker = (ref: any, markerIndex: any) => {
    if (ref && ref.leafletElement._popupHandlersAdded) {
      const matchingMarker = autoOpenMarkers.findIndex((autoMarker: any) => autoMarker === markerIndex)
      if (matchingMarker > -1) {
        setTimeout(() => {
          ref.leafletElement.openPopup()
        })
      }
    }
  }

  /* ======================
        useEffect()
  ====================== */
  // Center map on bounds after MapContainer's whenReady() prop does this: setMap(e.target)
  // The previous iteration of PrettyLeafletMap attempted to get a reference to Map with useRef,
  // but that approach was failing. Consequently, the map was failing to fit bounds.

  useEffect(() => {
    if (!doneLoadingData || !map) {
      return
    }

    // This logic will overwrite MapContainer's zoom and center settings.
    // Those props are supposedly optional. However, omitting them from
    // MapContainer causes the map to just render as a gray background.
    if (map && leafletMarkers.length > 0) {
      const latMarkers = leafletMarkers.map((marker: any) => {
        return L.marker([marker.lat, marker.lon])
      }) as Array<L.Layer>

      const group = new (L.featureGroup as any)(latMarkers)
      const bounds = group.getBounds()
      map.fitBounds(bounds)
    }
  }, [doneLoadingData, map])

  /* ======================
        useEffect()
  ====================== */
  // When component first renders check all markers for lat/lon info,
  // and push markers to leafletMarkersCleaned array.
  // If marker is missing lat/lon info, then attempt to obtain it by
  // calling apiAddressGetLatLon() when there is sufficient address info.
  // Set markers in state: setLeafletMarkers(leafletMarkersCleaned)

  useEffect(() => {
    async function loadMarkers() {
      const leafletMarkersCleaned = Array<any>()

      // Safe foreach (in) - using of (while nicer) will throw errors.
      for (const key in markers) {
        const marker = markers[key]

        if (marker === null) {
          continue
        }

        // Many markers will have marker.lat and marker.lon.
        // If they don't, then see if that marker has sufficient address info
        // to then call apiAddressGetLatLon(). If all the checks pass, call that API.
        if (!marker.lat || !marker.lon) {
          try {
            if (marker.address === null) {
              continue
            }

            if (marker.address?.address1 === null || marker.address?.address1 === '') {
              continue
            }

            if (marker.address?.city === null || marker.address?.city === '') {
              continue
            }

            if (marker.address?.state === null || marker.address?.state === '') {
              continue
            }

            const apiRequest = {
              address1: marker.address.address1,
              address2: marker.address.address2,
              city: marker.address.city,
              state: marker.address.state,
              zip: marker.address.zip,
              country: marker.address.country
            }

            await apiAddressGetLatLon(apiRequest).then((response) => {
              if (response.lat) {
                marker.lat = response.lat
                marker.lon = response.lon
                leafletMarkersCleaned.push(marker)
              }
            })
          } catch (err) {
            console.log(err)
          }
        } else {
          leafletMarkersCleaned.push(marker)
        }
      }

      setLeafletMarkers(leafletMarkersCleaned)
      setDoneLoadingData(true)
    }

    if (firstLoad) {
      setFirstLoad(false)
      loadMarkers()
    }
  }, [firstLoad, markers])

  /* ======================
          return
  ====================== */

  return (
    <Fragment>
      <SCLeafletMapStyleContainer style={{ height: height, width: '100%' }}>
        {!doneLoadingData ? (
          <Fragment>Loading...</Fragment>
        ) : (
          <MapContainer
            //center={[0, 0]}
            style={{
              border: '1px solid #dee2e6',
              borderRadius: 6,
              height: height,
              width: '100%'
            }}
            whenReady={
              // whenReady() does have access to e, but for some reason the types
              // are saying: '(e: any) => void' is not assignable to type '() => void'
              // This is likely due to a conflict in node_modules / yarn.lock, and
              // could potentially be resolved by wiping both of those. That said,
              // just leave this as it is...
              ((e: any) => {
                setMap(e.target)
              }) as () => void
            }
            zoom={1}
          >
            <TileLayer
              //attribution='&amp;copy <a href="https://www.mainstem.io">MainStem</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />

            {leafletMarkers.length > 0 && (
              <FeatureGroup>
                {leafletMarkers.map((marker: any, markerIndex: number) => {
                  const distanctText = center
                    ? `${formattedNumberAsString(distance(center.lat, center.lon, marker.lat, marker.lon), {
                        decimalPlaces: 0
                      })} miles`
                    : ''
                  return (
                    <Fragment key={markerIndex}>
                      <Marker
                        eventHandlers={{
                          click: () => {
                            if (onMarkerClicked) {
                              onMarkerClicked(marker)
                            }
                          }
                        }}
                        position={[marker.lat, marker.lon]}
                        ref={(ref: any) =>
                          autoOpenMarkers && autoOpenMarkers.length > 0 ? initMarker(ref, markerIndex) : null
                        }
                      >
                        {marker.popup ? <Popup>{marker.popup}</Popup> : null}
                      </Marker>

                      {center ? (
                        <TextPath
                          attributes={{
                            class: 'map-leaflet-text-distance'
                          }}
                          below
                          center
                          color='#440099'
                          offset={12}
                          opacity='0.4'
                          orientation={marker.lon < center.lon ? 'flip' : null}
                          positions={[
                            [center.lat, center.lon],
                            [marker.lat, marker.lon]
                          ]}
                          text={distanctText}
                        />
                      ) : null}
                    </Fragment>
                  )
                })}
              </FeatureGroup>
            )}
          </MapContainer>
        )}
      </SCLeafletMapStyleContainer>
    </Fragment>
  )
}

// LeafletMap.propTypes = {
//   markers: PropTypes.arrayOf(
//     PropTypes.shape({
//       lat: PropTypes.number,
//       lon: PropTypes.number,
//       address: PropTypes.shape({
//         address1: PropTypes.string.isRequired,
//         address2: PropTypes.string,
//         city: PropTypes.string.isRequired,
//         state: PropTypes.string.isRequired,
//         zip: PropTypes.string.isRequired,
//         country: PropTypes.string.isRequired
//       })
//     })
//   ).isRequired,
//   height: PropTypes.number.isRequired,
//   autoOpenMarkers: PropTypes.arrayOf(PropTypes.number),
//   onMarkerClicked: PropTypes.func
// }

export { LeafletMap }
