import styled from 'styled-components'

const Screen = styled.div`
  align-items: center;
  background-color: #00000085;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 9;
`

export {Screen}