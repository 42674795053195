import { selectFilter } from 'react-bootstrap-table2-filter'

export const RequisitionStatusFilter = selectFilter({
  options: {
    New: 'New',
    PendingApproval: 'Pending Approval',
    Returned: 'Returned',
    Approved: 'Approved',
    Rejected: 'Rejected',
    Cancelled: 'Cancelled'
  }
})
