// Local - Types
import { IEventBus } from './types'

const EventBus: IEventBus = {
  events: [],

  on(document, event, callback) {
    const fn = (e: any) => {
      callback(e.detail)
    }

    if (EventBus.events[event]) {
      document.removeEventListener(event, EventBus.events[event])
    }
    document.addEventListener(event, fn)
    EventBus.events[event] = fn
  },

  dispatch(document, event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }))
  },

  remove(document, event) {
    document.removeEventListener(event, EventBus.events[event])
  }
}

export { EventBus }
