// Routing
import { Link, useHistory } from 'react-router-dom'
// MainStem - UI
import {
  BasicTable,
  Button,
  ButtonGroup,
  FormattedCurrency,
  FormattedNumber,
  Grid,
  GridItem,
  InputCurrency,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MoreInfo,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faTrash } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { StaticImage } from 'components'
// Local - Styles
import { SCLineItemsTableBody, SCLineItemsTableHeader } from './styles'
// Local - Types
import { IPurchaseOrderCardLineItems, IPurchaseOrderCardLineItemsSelectedAllocation } from './types'
import { useState } from 'react'

const PurchaseOrderCardLineItems: React.FC<IPurchaseOrderCardLineItems> = ({
  isPunchOutGenerated,
  lineItems,
  readOnly,
  refreshPODetails,
  requisition,
  supplier
}: IPurchaseOrderCardLineItems) => {
  // Routing
  const history = useHistory()
  // View State
  const [amount, setAmount] = useState<string | undefined>(undefined)
  const [selectedAllocation, setSelectedAllocation] = useState<
    IPurchaseOrderCardLineItemsSelectedAllocation | undefined
  >(undefined)

  const handleClearAll = () => {
    const apiRequest = {
      allocations: lineItems.map((lineItem) => {
        return {
          lineItemId: lineItem.id
        }
      })
    }

    mainStemApi.api.purchaseOrdersAllocationsDelete(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('All Allocations Cleared!')
        refreshPODetails()
      } else {
        toast.error('Failed to clear all allocations!')
      }
    })
  }

  const handleApplyAll = () => {
    const apiRequest = {
      allocations: lineItems.map((lineItem) => {
        return {
          lineItemId: lineItem.id
        }
      })
    }

    mainStemApi.api.purchaseOrdersAllocationsEnable(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('All Allocations Enabled!')
        refreshPODetails()
      } else {
        toast.error('Failed to enabled all allocations!')
      }
    })
  }

  const handleClearSingle = (lineItemId, type) => {
    const apiRequest = {
      allocations: [
        {
          lineItemId,
          type
        }
      ]
    }

    mainStemApi.api.purchaseOrdersAllocationsDelete(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success(`${type} Allocation Cleared!`)
        refreshPODetails()
      } else {
        toast.error(`Failed to clear ${type} allocation!`)
      }
    })
  }

  const handleEditSingle = (lineItemId, type) => {
    setSelectedAllocation({
      lineItemId,
      type
    })
  }

  const handleClearLine = (lineItemId) => {
    const apiRequest = {
      allocations: [
        {
          lineItemId: lineItemId
        }
      ]
    }
    mainStemApi.api.purchaseOrdersAllocationsDelete(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('All Allocations Cleared!')
        refreshPODetails()
      } else {
        toast.error('Failed to clear all allocations!')
      }
    })
  }

  const handleApplyLine = (lineItemId) => {
    const apiRequest = {
      allocations: [
        {
          lineItemId: lineItemId
        }
      ]
    }

    mainStemApi.api.purchaseOrdersAllocationsEnable(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('All Allocations Enabled!')
        refreshPODetails()
      } else {
        toast.error('Failed to enabled all allocations!')
      }
    })
  }

  const handleUpdateAllocation = () => {
    if (!amount || !selectedAllocation) {
      return
    }

    const apiRequest = {
      amount: parseFloat(amount),
      lineItemId: selectedAllocation.lineItemId,
      type: selectedAllocation.type
    }

    mainStemApi.api.purchaseOrdersAllocationsUpdate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Allocation Updated!')
        setSelectedAllocation(undefined)
        setAmount(undefined)
        refreshPODetails()
      } else {
        toast.error('Failed to update allocation!')
      }
    })
  }

  return (
    <>
      <BasicTable bordered>
        <SCLineItemsTableHeader>
          <tr>
            <th colSpan={5} />
            <th colSpan={!readOnly ? 5 : 4} style={{ textAlign: 'center', borderLeft: '1px solid #888' }}>
              Accounting Allocations{' '}
              <MoreInfo text='If you choose to, you can allocate a certain amount of your shipping costs, fees, discounts and sales tax to each line item on the purchase order.' />
              {!readOnly && (
                <>
                  <br />
                  <Button
                    color='link'
                    onClick={() => {
                      handleClearAll()
                    }}
                    style={{ color: '#FF0000', fontWeight: 400, fontSize: 11 }}
                  >
                    <FontAwesomeIcon icon={faTrash} style={{ marginRight: 5 }} />
                    Clear All
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    color='link'
                    onClick={() => {
                      handleApplyAll()
                    }}
                    style={{ fontWeight: 400, fontSize: 11 }}
                  >
                    <FontAwesomeIcon icon={faPlusSquare} style={{ marginRight: 5 }} />
                    Apply All
                  </Button>
                </>
              )}
            </th>
          </tr>
          <tr>
            <th />
            <th>Product</th>
            <th style={{ textAlign: 'center' }}>Quantity</th>
            <th style={{ textAlign: 'center' }}>Price</th>
            <th style={{ textAlign: 'center' }}>Subtotal</th>
            <th style={{ textAlign: 'center', borderLeft: '1px solid #888' }}>Shipping</th>
            <th style={{ textAlign: 'center' }}>Fees</th>
            <th style={{ textAlign: 'center' }}>Discounts</th>
            <th style={{ textAlign: 'center' }}>Sales Tax</th>
            {!readOnly && <th style={{ textAlign: 'center' }}></th>}
          </tr>
        </SCLineItemsTableHeader>
        <SCLineItemsTableBody>
          {lineItems.map((lineItem) => {
            return (
              <tr key={lineItem.id}>
                <td style={{ width: 60 }}>
                  <StaticImage height={50} src={lineItem.product?.image || theme.images.notAvailable} width={50} />
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <div>
                    <div>
                      <Link to={`/products/details/${lineItem.product?.id}`}>
                        #{lineItem.product?.id}&nbsp;-&nbsp;{lineItem.product?.name}
                      </Link>
                    </div>
                    <div>
                      <span>{lineItem.product?.type}</span>
                    </div>
                  </div>
                </td>
                <td style={{ textAlign: 'center', width: 100 }}>
                  {lineItem.quantity ? <FormattedNumber value={lineItem.quantity} /> : <i>N/A</i>}
                </td>
                <td style={{ textAlign: 'center', width: 100 }}>
                  <FormattedCurrency decimalPlaces={5} trimZeros={true} value={lineItem.pricePerItem || 0} />
                </td>
                <td style={{ textAlign: 'center', width: 100 }}>
                  <FormattedCurrency value={lineItem.total || 0} />
                </td>
                <td
                  style={{
                    textAlign: 'center',
                    width: 100,
                    verticalAlign: 'middle',
                    borderLeft: '1px solid #888'
                  }}
                >
                  {lineItem.shippingPerItem !== null ? (
                    <>
                      <FormattedCurrency value={lineItem.shippingPerItem || 0} />
                      {!readOnly && (
                        <>
                          &nbsp;
                          <ButtonGroup>
                            <Button
                              color='link'
                              onClick={() => {
                                handleEditSingle(lineItem.id, 'Shipping')
                              }}
                            >
                              <FontAwesomeIcon icon={theme.icons.edit} />
                            </Button>
                            &nbsp;
                            <Button
                              color='link'
                              onClick={() => {
                                handleClearSingle(lineItem.id, 'Shipping')
                              }}
                              style={{ color: '#FF0000' }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </ButtonGroup>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <span style={{ color: '#888' }}>
                        <i>N/A</i>
                      </span>
                      {!readOnly && (
                        <>
                          &nbsp;
                          <Button
                            color='link'
                            onClick={() => {
                              setSelectedAllocation({
                                lineItemId: lineItem.id,
                                type: 'Shipping'
                              })
                            }}
                            style={{ color: '#888' }}
                          >
                            <FontAwesomeIcon icon={faPlusSquare} />
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </td>
                <td style={{ textAlign: 'center', width: 100, verticalAlign: 'middle' }}>
                  {lineItem.feesPerItem !== null ? (
                    <>
                      <FormattedCurrency value={lineItem.feesPerItem || 0} />
                      {!readOnly && (
                        <>
                          &nbsp;
                          <ButtonGroup>
                            <Button
                              color='link'
                              onClick={() => {
                                handleEditSingle(lineItem.id, 'Fees')
                              }}
                            >
                              <FontAwesomeIcon icon={theme.icons.edit} />
                            </Button>
                            &nbsp;
                            <Button
                              color='link'
                              onClick={() => {
                                handleClearSingle(lineItem.id, 'Fees')
                              }}
                              style={{ color: '#FF0000' }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </ButtonGroup>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <span style={{ color: '#888' }}>
                        <i>N/A</i>
                      </span>
                      {!readOnly && (
                        <>
                          &nbsp;
                          <Button
                            color='link'
                            onClick={() => {
                              setSelectedAllocation({
                                lineItemId: lineItem.id,
                                type: 'Fees'
                              })
                            }}
                            style={{ color: '#888' }}
                          >
                            <FontAwesomeIcon icon={faPlusSquare} />
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </td>
                <td style={{ textAlign: 'center', width: 100, verticalAlign: 'middle' }}>
                  {lineItem.discountsPerItem !== null ? (
                    <>
                      <FormattedCurrency value={lineItem.discountsPerItem || 0} />
                      {!readOnly && (
                        <>
                          &nbsp;
                          <ButtonGroup>
                            <Button
                              color='link'
                              onClick={() => {
                                handleEditSingle(lineItem.id, 'Discounts')
                              }}
                            >
                              <FontAwesomeIcon icon={theme.icons.edit} />
                            </Button>
                            &nbsp;
                            <Button
                              color='link'
                              onClick={() => {
                                handleClearSingle(lineItem.id, 'Discounts')
                              }}
                              style={{ color: '#FF0000' }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </ButtonGroup>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <span style={{ color: '#888' }}>
                        <i>N/A</i>
                      </span>
                      {!readOnly && (
                        <>
                          &nbsp;
                          <Button
                            color='link'
                            onClick={() => {
                              setSelectedAllocation({
                                lineItemId: lineItem.id,
                                type: 'Discounts'
                              })
                            }}
                            style={{ color: '#888' }}
                          >
                            <FontAwesomeIcon icon={faPlusSquare} />
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </td>
                <td style={{ textAlign: 'center', width: 100, verticalAlign: 'middle' }}>
                  {lineItem.salesTaxPerItem !== null ? (
                    <>
                      <FormattedCurrency value={lineItem.salesTaxPerItem || 0} />
                      {!readOnly && (
                        <>
                          &nbsp;
                          <ButtonGroup>
                            <Button
                              color='link'
                              onClick={() => {
                                handleEditSingle(lineItem.id, 'SalesTax')
                              }}
                            >
                              <FontAwesomeIcon icon={theme.icons.edit} />
                            </Button>
                            &nbsp;
                            <Button
                              color='link'
                              onClick={() => {
                                handleClearSingle(lineItem.id, 'SalesTax')
                              }}
                              style={{ color: '#FF0000' }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </ButtonGroup>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <span style={{ color: '#888' }}>
                        <i>N/A</i>
                      </span>
                      {!readOnly && (
                        <>
                          &nbsp;
                          <Button
                            color='link'
                            onClick={() => {
                              setSelectedAllocation({
                                lineItemId: lineItem.id,
                                type: 'SalesTax'
                              })
                            }}
                            style={{ color: '#888' }}
                          >
                            <FontAwesomeIcon icon={faPlusSquare} />
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </td>
                {!readOnly && (
                  <>
                    <td style={{ textAlign: 'center', width: 100, verticalAlign: 'middle' }}>
                      <Button
                        color='link'
                        onClick={() => {
                          handleClearLine(lineItem.id)
                        }}
                        style={{ color: '#FF0000', fontWeight: 400, fontSize: 11 }}
                      >
                        <FontAwesomeIcon icon={faTrash} style={{ marginRight: 5 }} />
                        Clear All
                      </Button>
                      <Button
                        color='link'
                        onClick={() => {
                          handleApplyLine(lineItem.id)
                        }}
                        style={{ fontWeight: 400, fontSize: 11 }}
                      >
                        <FontAwesomeIcon icon={faPlusSquare} style={{ marginRight: 5 }} />
                        Apply All
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            )
          })}
        </SCLineItemsTableBody>
      </BasicTable>
      <br />
      <Button
        block
        color='fancy'
        disabled={readOnly || isPunchOutGenerated}
        icon={theme.icons.new}
        onClick={() => {
          history.push(`/shop?order=${requisition.uuid}&supplier=${supplier.name}`)
        }}
      >
        Add More Products From &quot;{supplier.name}&quot;
      </Button>
      {selectedAllocation && (
        <Modal
          onClose={() => {
            setSelectedAllocation(undefined)
            setAmount(undefined)
          }}
        >
          <ModalHeader>
            Allocate {selectedAllocation.type} to Line Item #{selectedAllocation.lineItemId}
          </ModalHeader>
          <ModalBody>
            <InputCurrency
              label='Amount'
              onChange={(newValue) => {
                setAmount(newValue)
              }}
              value={amount}
            />
          </ModalBody>
          <ModalFooter>
            <Grid style={{ width: '100%' }}>
              <GridItem colSpan={6}>
                <Button
                  block={true}
                  color='danger'
                  icon={theme.icons.cancel}
                  onClick={() => {
                    setAmount(undefined)
                    setSelectedAllocation(undefined)
                  }}
                >
                  Cancel
                </Button>
              </GridItem>
              <GridItem colSpan={6}>
                <Button
                  block={true}
                  color='fancy'
                  icon={theme.icons.save}
                  onClick={() => {
                    handleUpdateAllocation()
                  }}
                >
                  Save Allocation
                </Button>
              </GridItem>
            </Grid>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export { PurchaseOrderCardLineItems }
