// DEPRECATE : API
import { APIIntegrationsLeafLogixProductsList } from 'api/integrations/leaf-logix/products/list'

// ALready used else where could make it a bit more reusable
export const leafLogixLoadProducts = (customerIntegrationSettingID: number, setLoading: any, setter: any) => {
  const apiRequest = {
    customerIntegrationSettingID: customerIntegrationSettingID
  }
  setLoading(true)
  return APIIntegrationsLeafLogixProductsList(apiRequest).then((response) => {
    const mappedProducts = response.map((product: any) => {
      return {
        ...product,
        value: product.productId,
        label: `[${product.productId}] ${product.internalName}`
      }
    })
    setter(mappedProducts)
    setLoading(false)
    return mappedProducts
  })
}
