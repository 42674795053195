import styled from 'styled-components'
import { IButtonClear } from './types'

export const SCButtonClear = styled.div<IButtonClear>`
  &:hover {    
    cursor: pointer;

    & > svg {
      color: ${({ hoverColor }) => hoverColor} !important;
    }
  }
`
